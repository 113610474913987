/**
 * Concat baseUrl and url in a "/" safe manner
 */
export function buildUrl(baseUrl: string, url: string) {
  if (!baseUrl) {
    baseUrl = '/';
  }
  if (baseUrl[baseUrl.length - 1] !== '/' && url[0] !== '/') {
    return `${baseUrl}/${url}`;
  }
  if (baseUrl[baseUrl.length - 1] === '/' && url[0] === '/') {
    return `${baseUrl}${url.substring(1)}`;
  }
  return `${baseUrl}${url}`;
}
