// Expected to be called only on client side
export const isInternalHost = () =>
  typeof window !== 'undefined' && window?.location?.host?.includes('k8s.hipages');
export const isLocalhost = () =>
  typeof window !== 'undefined' && window?.location?.host?.search(/(localhost)|(www\.test)/) >= 0;

export const isProdHost = () => !isInternalHost() && !isLocalhost();

// Works for both client side and server side
// See https://remix.run/docs/en/main/guides/gotchas
export const isServerSide: () => boolean = () => {
  return typeof document === 'undefined';
};

export const isClientSide = () => {
  return !isServerSide();
};

export const isProduction: () => boolean = () => {
  if (isServerSide()) {
    return process.env.NODE_ENV === 'production';
  }

  return isProdHost();
};
